import { useMutation as useMutationRQ, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type mutationParams = {
    action: 'update' | 'create' | 'delete';
    id?: number;
    values?: any;
};

export default function useMutation({
    queryKey,
    updateFn,
    createFn,
    deleteFn,

    onDone,
}: {
    queryKey: any;
    updateFn?: (id: number, values: any) => any;
    createFn?: (values: any) => any;
    deleteFn?: (id: number) => any;

    onDone?: ( action: mutationParams['action'] ) => void;
}) {
    const queryClient = useQueryClient()
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState<'update' | 'create' | 'delete' | null>(null);

    const mutation = useMutationRQ({
        mutationFn: async (values: mutationParams) => {
            let res = null;
            setLoading(true);
            switch (values.action) {
                case 'update':
                    if (values.id && updateFn) {
                        messageApi.open({
                            type: 'loading',
                            content: t('جاري التحديث'),
                            duration: 0,
                        });
                        setAction('update');
                        res = updateFn(values.id, values.values);
                    }
                    break;
                case 'create':
                    if (createFn) {
                        messageApi.open({
                            type: 'loading',
                            content: t('جاري الإنشاء'),
                            duration: 0,
                        });
                        setAction('create');
                        res = createFn(values.values);
                    }
                    break;
                case 'delete':
                    if (values.id && deleteFn) {
                        messageApi.open({
                            type: 'loading',
                            content: t('جاري الحذف'),
                            duration: 0,
                        });
                        setAction('delete');
                        res = deleteFn(values.id);
                    }
                    break;
            }
            return res;
        },
        onSuccess: (data: any) => {

            const key = queryKey[0] || queryKey;

            const queries = queryClient.getQueryCache().findAll(key).filter((query: any) => {
                const queryKey = query.queryKey[0] || query.queryKey;
                return queryKey === key;
            });

            queries.forEach((query: any) => {

                queryClient.setQueryData(query.queryKey, (oldData: any) => {
                    let old = oldData as any;
                    if (!old) {
                        queryClient.invalidateQueries(queryKey);
                        return old;
                    }

                    if (action === 'create') {
                        old.data.unshift(data);
                    } else if (action === 'update') {
                        if (!data.id) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        if (old.data === undefined) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        const index = old.data.findIndex((item: any) => item.id === data.id);
                        if (index === -1) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        old.data[index] = {
                            ...old.data[index],
                            ...data,
                        };
                    } else if (action === 'delete') {
                        if (!data.id) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        if (old.data === undefined) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        const index = old.data.findIndex((item: any) => item.id === data.id);
                        if (index === -1) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        old.data.splice(index, 1);
                    }

                    // this is very important to set the data to empty array
                    // and the re assinn the new data to it
                    // if you don't do that the new content will not
                    // gre re rendered and updated unless you navigate away and back
                    // this is a bug in react-query and only this trick could solve it
                    // after 5 hours of debugging :(
                    // issue on git hub: https://github.com/apollographql/react-apollo/issues/3816
                    // there are other issue on theis topic also
                    queryClient.setQueryData(query.queryKey, {
                        ...old,
                        data: [],
                    });

                    return old;

                });

            });

            setLoading(false);
            messageApi.destroy();
            messageApi.open({
                type: 'success',
                content: t('تمت العملية بنجاح'),
            });
            onDone && onDone(action as mutationParams['action']);

        },

        onError: (error: any) => {
            
            setLoading(false);
            messageApi.destroy();
            messageApi.open({
                type: 'error',
                content: error?.message,
            });

            onDone && onDone( action as mutationParams['action'] );
        },
    });

    return {
        mutation,
        contextHolder,
        loading,

        create: (values: any) => mutation.mutate({ action: 'create', values }),
        createAsync: async (values: any) => await mutation.mutateAsync({ action: 'create', values }),

        update: (id: number, values: any) => mutation.mutate({ action: 'update', id, values }),
        updateAsync: async (id: number, values: any) => await mutation.mutateAsync({ action: 'update', id, values }),

        delete: (id: number) => mutation.mutate({ action: 'delete', id }),
        deleteAsync: async (id: number) => await mutation.mutateAsync({ action: 'delete', id }),
    };
}
