import { apiWithInterceptor } from '.';
import { CategoryType } from '../types';

export const getCategories = async (params: {
    limit?: number;
    offset?: number;
    search?: string;
}): Promise<CategoryType> => {
    return await apiWithInterceptor.get('/categories', { params });
}

export const getCategory = async (id: number): Promise<CategoryType> => {
    return await apiWithInterceptor.get(`/categories/${id}`);
}

export const createCategory = async (data: { name: string }): Promise<CategoryType> => {
    return await apiWithInterceptor.post('/categories', data);
}

export const updateCategory = async (id: number, data: { name: string }): Promise<CategoryType> => {
    return await apiWithInterceptor.put(`/categories/${id}`, data);
}

export const deleteCategory = async (id: number): Promise<CategoryType> => {
    return await apiWithInterceptor.delete(`/categories/${id}`);
}