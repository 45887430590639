import { useRoutes } from 'react-router-dom';

import {
    DashboardLayout,
    HalfScreenLayout,
    NotFound,
} from "./layout";

import { CategoriesView, LoginView, UnitsView, ItemsView, DashboardView } from './views';

// import {
//     Home as HomeView,
// } from "./views";

export default function Router() {

    return useRoutes([
        {
            path: '/',
            element: <HalfScreenLayout/>,
            children: [
                {
                    path: '',
                    element: <LoginView/>
                },
            ]
        },
        {
            path: '/app',
            element: <DashboardLayout/>,
            children: [
                {
                    path: '',
                    element: <DashboardView/>
                },
                {
                    path: 'units',
                    children: [
                        {
                            path: '',
                            element: <UnitsView/>
                        },
                    ]
                },
                {
                    path: 'categories',
                    children: [
                        {
                            path: '',
                            element: <CategoriesView/>
                        },
                    ]
                },
                {
                    path: 'items',
                    children: [
                        {
                            path: '',
                            element: <ItemsView/>
                        },
                    ]
                },
            ]
        },
        {
            path: '*',
            element: <NotFound/>
        },
    ]);

}


