import { SmileOutlined } from '@ant-design/icons';
import { Button, Result, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export default function Dashboard() {

    const { t } = useTranslation();

    return (
        <Result
            icon={<SmileOutlined />}
            title={t('مرحباً بك في لوحة التحكم!')}
            extra={<Typography.Paragraph>{t('هذه الصفحة تحت الإنشاء، يمكنك المحاولة مرة أخرى لاحقاً. وكذلك يمكنك تصفح الصفحات الأخرى.')}</Typography.Paragraph>}
        />
    )

};