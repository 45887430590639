import { useQuery } from '@tanstack/react-query'

import {
    getUnits,
} from '../routes/units';

import {
    ListResponse,
    QueryResponseType,
    UnitType
} from '../types';

export const useQueryUnits = (params: {
    limit?: number;
    offset?: number;
    search?: string;
}) => {
    
    const query = useQuery(
        {
            queryKey: ['units', params],
            queryFn: () => getUnits( { ...params } ),
        }
    ) as QueryResponseType<ListResponse<UnitType[]>>;

    return query;
};