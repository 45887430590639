import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useMutationForm } from "../../hooks";
import { createCategory, deleteCategory, updateCategory } from "../../api/routes/categories";

export default function useCategoriesForm() {

    const { t } = useTranslation();

    const form = useMutationForm((
        <Form.Item
            label={t('الاسم')}
            name="name"
            initialValue={""}
            rules={[{ required: true, message: t('الرجاء إدخال الاسم') }]}
        >
            <Input />
        </Form.Item>
    ), {
        title: t('التصنيف'),
        queryKey: ['categories'],
        updateFn: updateCategory,
        createFn: createCategory,
        deleteFn: deleteCategory,
    });

    return {
        setOpen: form.setOpen,
        contextHolder: form.contextHolder,
    };

}