import { Flex, Table, Input, Button, Space, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ViewTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useQueryItems } from "../../api/queries/getItems";
import { ItemType } from "../../api/types";
import useItemsForm from "./useItemsForm";
import { useQueryUnits } from "../../api/queries/getUnits";
import { useQueryCategories } from "../../api/queries/getCategories";

export default function Units() {

    const { t } = useTranslation();

    const [unitsSearch, setUnitsSearch] = useState<string>('');
    const units = useQueryUnits({ search: unitsSearch });

    const [categoriesSearch, setCategoriesSearch] = useState<string>('');
    const categories = useQueryCategories({ search: categoriesSearch });

    const columns = useMemo(() => [
        {
            title: t('الاسم'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('الكمية'),
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: t('الوحدة'),
            dataIndex: 'unit',
            key: 'unit',
            render: (unit: ItemType['unit']) => unit.name,
        },
        {
            title: t('التصنيف'),
            dataIndex: 'category',
            key: 'category',
            render: (category: ItemType['category']) => category.name,
        },
        {
            title: t('الرمز'),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t('الملاحظات'),
            dataIndex: 'notes',
            key: 'notes',
        },
        {
            title: t('الفاتورة'),
            dataIndex: 'invoice',
            key: 'invoice',
        },
    ], [t]);

    const [params, setParams] = useState({ limit: 10, offset: 0, search: '' });
    const [unitId, setUnitId] = useState<number | undefined>();
    const [categoryId, setCategoryId] = useState<number | undefined>();
    const items = useQueryItems({
        ...params,
        ...(unitId && { unit_id: unitId }),
        ...(categoryId && { category_id: categoryId }),
    });

    const form = useItemsForm();

    return (
        <Flex vertical gap={20} style={{ padding: '20px 0' }}>
            {form.contextHolder}
            <ViewTitle title={t('الموجودات')} />
            <Space>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        form.setOpen(true);
                    }}
                >
                    {t('إضافة')}
                </Button>
                <Select
                    options={categories.data?.data?.map((category: ItemType['category']) => ({ value: category.id, label: category.name }))}
                    onSearch={setCategoriesSearch}
                    showSearch={true}
                    placeholder={t('التصنيف')}
                    style={{ width: 200 }}
                    onChange={categoryId => setCategoryId(categoryId)}
                    value={categoryId}
                    allowClear
                />
                <Select
                    options={units.data?.data?.map((unit: ItemType['unit']) => ({ value: unit.id, label: unit.name }))}
                    onSearch={setUnitsSearch}
                    showSearch={true}
                    placeholder={t('الوحدة')}
                    style={{ width: 200 }}
                    onChange={unitId => setUnitId(unitId)}
                    value={unitId}
                    allowClear
                />
            </Space>
            <Input.Search
                enterButton
                placeholder={t('بحث')}
                onSearch={search => setParams({ ...params, search })}
                onChange={e => { setParams({ ...params, search: e.target.value }) }}
                loading={items.isLoading}
            />
            <Table
                loading={items.isLoading}
                columns={columns}
                dataSource={items.data?.data as any}
                pagination={{
                    current: params.offset / params.limit + 1,
                    pageSize: params.limit,
                    total: items.data?.total,
                    onChange: (page, pageSize) => setParams({ ...params, offset: (page - 1) * pageSize, limit: pageSize }),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            form.setOpen(true, record as ItemType);
                        },
                        style: { cursor: 'pointer' }
                    }
                }}
            />
        </Flex>
    )

}