import { useQuery } from '@tanstack/react-query'

import {
    getCategories,
} from '../routes/categories';

import {
    QueryResponseType,
    CategoryType,
    ListResponse
} from '../types';

export const useQueryCategories = (params: {
    limit?: number;
    offset?: number;
    search?: string;
}) => {
    
    const query = useQuery(
        {
            queryKey: ['categories', params],
            queryFn: () => getCategories( { ...params } ),
        }
    ) as QueryResponseType<ListResponse<CategoryType[]>>;

    return query;
};