import { Flex, Table, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ViewTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useQueryCategories } from "../../api/queries/getCategories";
import { CategoryType } from "../../api/types";
import useCategoriesForm from "./useCategoriesForm";

export default function Units() {

    const { t } = useTranslation();

    const columns = useMemo(() => [
        {
            title: t('الاسم'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('العناصر'),
            dataIndex: 'items',
            key: 'items',
        },
    ], [t]);

    const [params, setParams] = useState({ limit: 10, offset: 0, search: '' });
    const categories = useQueryCategories({ ...params });

    const form = useCategoriesForm();

    return (
        <Flex vertical gap={20} style={{ padding: '20px 0' }}>
            {form.contextHolder}
            <ViewTitle title={t('التصنيفات')} />
            <div>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        form.setOpen(true);
                    }}
                >
                    {t('إضافة')}
                </Button>
            </div>
            <Input.Search
                enterButton
                placeholder={t('بحث')}
                onSearch={search => setParams({ ...params, search })}
                onChange={e => { setParams({ ...params, search: e.target.value }) }}
                loading={categories.isLoading}
            />
            <Table
                loading={categories.isLoading}
                columns={columns}
                dataSource={categories?.data?.data as CategoryType[]}
                pagination={{
                    current: params.offset / params.limit + 1,
                    pageSize: params.limit,
                    total: categories.data?.total,
                    onChange: (page, pageSize) => setParams({ ...params, offset: (page-1)*pageSize, limit: pageSize }),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => form.setOpen(true, record as CategoryType),
                        style: { cursor: 'pointer' }
                    }
                }}
            />
        </Flex>
    )

}