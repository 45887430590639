import { Flex, Table, Input, Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ViewTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useQueryUnits } from "../../api/queries/getUnits";
import { UnitType } from "../../api/types";
import { createUnit, deleteUnit, updateUnit } from "../../api/routes/units";
import { useMutationForm } from "../../hooks";

export default function Units() {

    const { t } = useTranslation();

    const columns = useMemo(() => [
        {
            title: t('الاسم'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('العناصر'),
            dataIndex: 'items',
            key: 'items',
        },
    ], [t]);

    const [params, setParams] = useState({ limit: 10, offset: 0, search: '' });
    const units = useQueryUnits({ ...params });

    const form = useMutationForm((
        <Form.Item
            label={t('الاسم')}
            name="name"
            initialValue={""}
            rules={[{ required: true, message: t('الرجاء إدخال الاسم') }]}
        >
            <Input />
        </Form.Item>
    ), {
        title: t('وحدة'),
        queryKey: ['units'],
        updateFn: updateUnit,
        createFn: createUnit,
        deleteFn: deleteUnit,
    });

    return (
        <Flex vertical gap={20} style={{ padding: '20px 0' }}>
            <ViewTitle title={t('الوحدات')} />
            {form.contextHolder}
            <div>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        form.setOpen(true);
                    }}
                >
                    {t('إضافة')}
                </Button>
            </div>
            <Input.Search
                enterButton
                placeholder={t('بحث')}
                onSearch={search => setParams({ ...params, search })}
                onChange={e => { setParams({ ...params, search: e.target.value }) }}
                loading={units.isLoading}
            />
            <Table
                loading={units.isLoading}
                columns={columns}
                dataSource={units.data?.data as UnitType[]}
                pagination={{
                    current: params.offset / params.limit + 1,
                    pageSize: params.limit,
                    total: units.data?.total,
                    onChange: (page, pageSize) => setParams({ ...params, offset: (page - 1) * pageSize, limit: pageSize }),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => form.setOpen(true, record as UnitType),
                        style: { cursor: 'pointer' }
                    }
                }}
            />
        </Flex>
    )

}