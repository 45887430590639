import { apiWithInterceptor } from '.';
import { UnitType } from '../types';

export const getUnits = async (params: {
    limit?: number;
    offset?: number;
    search?: string;
}): Promise<UnitType> => {
    return await apiWithInterceptor.get('/units', { params });
}

export const getUnit = async (id: number): Promise<UnitType> => {
    return await apiWithInterceptor.get(`/units/${id}`);
}

export const createUnit = async (data: { name: string }): Promise<UnitType> => {
    return await apiWithInterceptor.post('/units', data);
}

export const updateUnit = async (id: number, data: { name: string }): Promise<UnitType> => {
    return await apiWithInterceptor.put(`/units/${id}`, data);
}

export const deleteUnit = async (id: number): Promise<UnitType> => {
    return await apiWithInterceptor.delete(`/units/${id}`);
}