import axios, { AxiosError, AxiosResponse } from 'axios'

export const CONTENT_TYPE = "application/json";
export const DOMAIN = {
    development: "http://inventory.local/wp-json/app/v1/",
    production: "https://inventory-api.alnoor.edu.iq/wp-json/app/v1/",
}

const apiWithoutInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? DOMAIN.production
        : DOMAIN.development
        }`,
    headers: {
        "Accept": CONTENT_TYPE,
    },
});

const apiWithInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? DOMAIN.production
        : DOMAIN.development
        }`,
    headers: {
        "Accept": CONTENT_TYPE,
    },
});

apiWithInterceptor.interceptors.request.use(async (config) => {
    let token = localStorage?.getItem("token")?.replace(/['"]+/g, "");

    if (token) {
        if (!config.headers) config.headers = {} as any;
        config.headers.Authorization = `Bearer ${token}`;
    }

    // check query params if any of them is empty string then remove it
    if (config.params) {
        Object.keys(config.params).forEach((key) => {
            if (config.params[key] === "") {
                delete config.params[key];
            }
        });
    }

    // check data if any of them is empty string then remove it
    if (config.data) {
        Object.keys(config.data).forEach((key) => {
            if (config.data[key] === "") {
                delete config.data[key];
            }
        });
    }

    return config;
});

interface SuccessResponse<T> {
    code: string;
    message: string;
    status: number;
    data: T;
}

interface ErrorResponse {
    code: string;
    data: {
        status: number;
    };
    message: string;
}

// filter all responses and check if the status is 200 and the key success is true then return the data
[apiWithInterceptor, apiWithoutInterceptor].forEach((api) => {
    api.interceptors.response.use((response: AxiosResponse<any, SuccessResponse<any>>) => {
        const data = response.data;
        if (data.status !== 200) {
            return Promise.reject({
                code: "unknown_error",
                message: "The host returned a non-200 status code on a successful response",
                data: { status: 500 }
            });
        }
        return data.data;
    }, (error: AxiosError<ErrorResponse>) => {

        // 401 means unauthorized
        if (error.response?.status === 401) {
            localStorage.removeItem("token");
            window.location.href = "/";
        }

        const data = error.response?.data || {
            code: "unknown_error",
            message: "Unknown error",
            data: { status: 500 }
        };

        return Promise.reject(data);
    });
});


export { apiWithInterceptor, apiWithoutInterceptor };