import { Flex, Form, Input, InputNumber, Select } from "antd";
import { ItemType, UnitType, CategoryType } from "../../api/types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useQueryUnits } from "../../api/queries/getUnits";
import { useQueryCategories } from "../../api/queries/getCategories";
import { createItem, deleteItem, updateItem } from "../../api/routes/items";
import { useMutationForm } from "../../hooks";

const unionBy = (key: string, x: any[], y: any[]) => {

    const x_keys: (typeof key)[] = x.map((item) => item[key]);
    const y_keys: (typeof key)[] = y.map((item) => item[key]);
    const ids: (typeof key)[] = [...x_keys, ...y_keys];
    const processedIds: (typeof key)[] = [];

    const data: any[] = [];

    ids.forEach((id) => {
        if (!processedIds.includes(id)) {
            processedIds.push(id);
            data.push(
                x_keys.includes(id) ? x.find((item) => item[key] === id) : y.find((item) => item[key] === id)
            );
        }
    });

    return data;

}

export default function useItemsForm() {

    const { t } = useTranslation();
    const [item, setItem] = useState<ItemType | null>(null);

    const [unitsSearch, setUnitsSearch] = useState<string>('');
    const units = useQueryUnits({ search: unitsSearch });
    const [unitsData, setUnitsData] = useState<UnitType[]>([]);

    const [categoriesSearch, setCategoriesSearch] = useState<string>('');
    const categories = useQueryCategories({ search: categoriesSearch });
    const [categoriesData, setCategoriesData] = useState<CategoryType[]>([]);

    useEffect(() => {
        
        setUnitsData(unionBy(
            'id',
            item?.unit ? [item.unit] : [],
            units.isSuccess ? (units.data.data as any) : []
        ));
        
        setCategoriesData(unionBy(
            'id',
            item?.category ? [item.category] : [],
            categories.isSuccess ? (categories.data.data as any) : []
        ));

    }, [item, units.dataUpdatedAt, categories.dataUpdatedAt, units.data?.data, categories.data?.data]);

    const form = useMutationForm((
        <>
            <Form.Item
                label={t('الاسم')}
                name="name"
                initialValue={""}
                rules={[{ required: true, message: t('الرجاء إدخال الاسم') }]}
            >
                <Input />
            </Form.Item>
            <Flex gap={20}>
                <Form.Item
                    style={{
                        width: '100%',
                    }}
                    label={t('الكمية')}
                    name="value"
                    initialValue={""}
                    rules={[{ required: true, message: t('الرجاء إدخال القيمة') }]}
                >
                    <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
                <Form.Item
                    style={{
                        width: '100%',
                    }}
                    label={t('الوحدة')}
                    name="unit_id"
                    initialValue={""}
                    rules={[{ required: true, message: t('الرجاء إدخال الوحدة') }]}
                >
                    <Select
                        options={unitsData ? unitsData.map((unit: UnitType) => ({ value: unit.id, label: unit.name })) : []}
                        onSearch={setUnitsSearch}
                        showSearch={true}
                    />
                </Form.Item>
            </Flex>
            <Flex gap={20}>
                <Form.Item
                    style={{
                        width: ' 100%',
                    }}
                    label={t('التصنيف')}
                    name="category_id"
                    initialValue={""}
                    rules={[{ required: true, message: t('الرجاء إدخال التصنيف') }]}
                >
                    <Select
                        options={categoriesData ? categoriesData.map((category: CategoryType) => ({ value: category.id, label: category.name })) : []}
                        onSearch={setCategoriesSearch}
                        showSearch={true}
                    />
                </Form.Item>
                <Form.Item
                    style={{
                        width: ' 100%',
                    }}
                    label={t('الفاتورة')}
                    name="invoice"
                    initialValue={""}
                >
                    <Input />
                </Form.Item>
            </Flex>
            <Form.Item
                label={t('الرمز')}
                name="code"
                initialValue={""}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t('الملاحظات')}
                name="notes"
                initialValue={""}
            >
                <Input.TextArea />
            </Form.Item>
        </>),
        {
            title: t('الموجودات'),
            queryKey: ['items'],
            updateFn: updateItem,
            createFn: createItem,
            deleteFn: deleteItem,
        }
    );

    return {
        setOpen: (open: boolean, item?: ItemType) => {
            if (item) {
                setItem(item);
            }
            form.setOpen(open, item);
        },
        contextHolder: form.contextHolder,
    };

}