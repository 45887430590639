import { useQuery } from '@tanstack/react-query'

import {
    getItems,
} from '../routes/items';

import {
    QueryResponseType,
    ItemType,
    ListResponse
} from '../types';

export const useQueryItems = (params: {
    limit?: number;
    offset?: number;
    search?: string;
    category_id?: number;
    unit_id?: number;
}) => {
    
    const query = useQuery(
        {
            queryKey: ['items', params],
            queryFn: () => getItems( { ...params } ),
        }
    ) as QueryResponseType<ListResponse<ItemType[]>>;

    return query;
};