import React, { useEffect, useMemo, useState } from 'react';
import {
    DashboardOutlined,
    ContainerOutlined,
    PieChartOutlined,
    AppstoreOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Flex, Layout, Menu, Space, Typography, theme } from 'antd';
import { Logo, ScrollArea } from '../../components';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import './style.css';
import { HeaderWidget } from '../../widgets';
import { useQueryAccount } from '../../api/queries/getAccount';
import { LoadingLayout } from '..';
import { Outlet, useNavigate } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    onClick?: () => void,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        onClick,
        label,
    } as MenuItem;
}

const App: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentTab, setCurrentTab] = useState<string>('/app');
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const { t } = useTranslation();

    const account = useQueryAccount();
    useEffect(() => {
        if (account.isSuccess) {
            setLoading(false);
        }
    }, [account]);

    const navigate = useNavigate();

    const currentPath = window.location.pathname;
    useEffect(() => {
        setCurrentTab(currentPath);
    }, [currentPath]);

    const items = useMemo<MenuItem[]>(() => {
        const basePath = '/app';
        const items = [
            getItem(t('الرئيسية'), '/app', <PieChartOutlined />, () => navigate(`${basePath}`))
            // getItem('User', 'sub1', <UserOutlined />, () => {
            // },[
            //     getItem('Tom', '3'),
            //     getItem('Bill', '4'),
            //     getItem('Alex', '5'),
            // ]),
        ];

        const capabilities = account.data?.capabilities || [];

        if (capabilities.includes('manage_items')) {
            items.push(getItem(
                t('الموجودات'),
                '/app/items',
                <ContainerOutlined />,
                () => navigate(`${basePath}/items`)
            ));
        }

        if (capabilities.includes('manage_units')) {
            items.push(getItem(
                t('الوحدات'),
                '/app/units',
                <DashboardOutlined />,
                () => navigate(`${basePath}/units`)
            ));
        }

        if (capabilities.includes('manage_categories')) {
            items.push(getItem(
                t('التصنيفات'),
                '/app/categories',
                <AppstoreOutlined />,
                () => navigate(`${basePath}/categories`)
            ));
        }

        return items;
    }, [account]);

    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            window.location.href = '/';
        }
    }, [token]);

    if (loading) {
        return <LoadingLayout
            msg={t('جاري تحميل البيانات')}
        />;
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                width={250}
                className='layout-dashboard-sider bg-gray-9'
                style={{
                    position: 'sticky',
                }}
            >
                <ScrollArea visibleScrollbar>
                    <Flex
                        content='center'
                        align='center'
                        vertical
                        className='layout-dashboard-sider-logo'
                        gap={10}
                    >
                        <Logo width={100} />
                        <Space direction='vertical' size={2}>
                            <Title level={4} style={{ color: 'white', margin: 0 }}>جامعة النور</Title>
                            <Typography.Text style={{ color: 'white' }}>نظام الموجودات</Typography.Text>
                        </Space>
                    </Flex>
                    <Menu
                        theme="dark"
                        defaultSelectedKeys={[currentTab]}
                        mode="inline"
                        items={items}
                        className='bg-gray-9'
                    />
                </ScrollArea>
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: 'transparent', position: 'sticky', top: 0, zIndex: 999 }}>
                    <HeaderWidget />
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <div>
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    {/* {t('كلية النور الجامعة')} ©{new Date().getFullYear()} {t('تم تطويره بواسطة شركة سوفت واي')} */}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default App;