import { Form, Input, Button, Flex, Typography, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Logo } from '../components';
import { useEffect, useState } from 'react';
import { login } from '../api/routes/auth';
import { useNavigate } from 'react-router-dom';
import { getAccount } from '../api/routes/account';

type LayoutType = Parameters<typeof Form>[0]['layout'];

export default function Login() {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const onFinish = (values: any) => {
        setLoading(true);
        login(values).then((data) => {
            localStorage.setItem('token', data.token);
            navigate('/app');
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

    const [form] = Form.useForm<{ email: string, password: string }>();
    Form.useWatch(() => setError(null), form);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            getAccount().then(() => {
                navigate('/app');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <Flex vertical align="start" gap={20} style={{ width: 400, padding: '0 20px' }}>
            <Flex vertical gap={10}>
                <Logo width={100} />
                <Typography.Title level={4} style={{ margin: 0 }}>{t('تسجيل الدخول إلى نظام الموجودات')}</Typography.Title>
                <Typography.Text>{t('قم بإدخال المعلومات المطلوبة لللمتابعة في استخدام النظام')}</Typography.Text>
            </Flex>
            <div style={{ height: '5%' }} />

            {
                error && (
                    <Alert message={error} type="error" showIcon />
                )
            }

            {/* <div id="g_id_onload"
                data-client_id="1027951851732-7homs21qhrfskn7m7nst8h9um4q0otj4.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-login_uri="https://curiosity.qarar.me/wp-json/qarar-app/v1/ar/auth/google/redirect?redirect=https://rocket.qarar.me"
                data-auto_prompt="false">
            </div>

            <div className="g_id_signin"
                style={{
                    overflow: 'hidden',
                    borderRadius: 4,
                }}
                data-type="standard"
                data-shape="rectangular"
                data-theme="filled_blue"
                data-text="signin_with"
                data-size="large"
                data-logo_alignment="left">
            </div> */}

            <Form
                onFinish={onFinish}
                form={form}
                layout={formLayout}
                style={{ width: '100%' }}
            >
                <Form.Item
                    label={t('البريد الإلكتروني')}
                    name="email"
                    rules={[{ required: true, message: t('الرجاء إدخال البريد الإلكتروني') }]}
                >
                    <Input type="email" disabled={loading} />
                </Form.Item>

                <Form.Item
                    label={t('كلمة المرور')}
                    name="password"
                    rules={[{ required: true, message: t('الرجاء إدخال كلمة المرور') }]}
                >
                    <Input.Password disabled={loading} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                        {t('تسجيل الدخول')}
                    </Button>
                </Form.Item>
            </Form>
        </Flex>
    );
}