import { apiWithInterceptor } from '.';
import { ItemType } from '../types';

export const getItems = async (params: {
    limit?: number;
    offset?: number;
    search?: string;
    category_id?: number;
    unit_id?: number;
}): Promise<ItemType> => {
    return await apiWithInterceptor.get('/items', { params });
}

export const getItem = async (id: number): Promise<ItemType> => {
    return await apiWithInterceptor.get(`/items/${id}`);
}

export const createItem = async (data: {
    name: string,
    unit_id: number,
    category_id: number,
    value: number,
    code?: string,
    notes?: string,
    invoice?: string
}): Promise<ItemType> => {
    return await apiWithInterceptor.post('/items', data);
}

export const updateItem = async (id: number, data: {
    name?: string,
    unit_id?: number,
    category_id?: number,
    value: number,
    code?: string,
    notes?: string,
    invoice?: string
}): Promise<ItemType> => {
    return await apiWithInterceptor.put(`/items/${id}`, data);
}

export const deleteItem = async (id: number): Promise<ItemType> => {
    return await apiWithInterceptor.delete(`/items/${id}`);
}