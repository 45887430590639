import { apiWithoutInterceptor, apiWithInterceptor } from './index';

export const login = async (data: {
    email: string;
    password: string;
}) : Promise<{
    token: string;
    user: any;
}> => {
    return await apiWithoutInterceptor.post('/auth/classic', data);
}

export const logout = async () => {
    return await apiWithInterceptor.delete('/auth');
}